import { SgBootstrapTheme } from '@sgwt/sgwt-widgets-react';

interface GlobalConfiguration {
  matomo: number;
  environment: string;
  apm: {
    enabled: boolean;
    serverUrl: string;
    serviceName: string;
  };
  sgConnect: {
    clientId: string;
    endpoint: string;
    redirectUri: string;
  };
  apiUrl: string;
  cdn: string;
  parameters: {
    theme: Theme;
    sgbs_version: string;
    breakpoint: Breakpoint;
    view: View;
  };
  demoSiteUrl: string;
}

declare global {
  interface Window {
    globalConfiguration: GlobalConfiguration;

    loadSgBootstrap: (version: string, theme: SgBootstrapTheme) => void;

    SG_BOOTSTRAP_VERSION: string;
  }
}

export type Breakpoint = 'auto' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type View = 'horizontal' | 'vertical';

export type Size = 'sm' | 'md' | 'lg' | 'xl';

export type Theme = 'standard' | 'dark';

export type SidebarMode = 'minimal' | 'default';

export type AvailableTabs = 'components' | 'templates';

export enum PlaygroundRight {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
  None = 'none',
}

export type PRIVACY_KEYS = 'official' | 'private' | 'public';

export type PROJECT_PRIVACY_KEYS = 'private' | 'public';

export interface PrivacyValue {
  id: PRIVACY_KEYS;
  name: string;
  icon: string;
}

export const PRIVACY_PRIVATE: PrivacyValue = {
  id: 'private',
  name: 'Private - Only people who you choose',
  icon: 'lock',
};

export const PRIVACY_PUBLIC: PrivacyValue = {
  id: 'public',
  name: 'Public - Everyone in your organisation',
  icon: 'lock_open',
};

export const PRIVACY_OFFICIAL: PrivacyValue = {
  id: 'official',
  name: 'Official - Everyone',
  icon: 'groups',
};

export enum StorageKey {
  CODE_TEMPLATE = 'sgm-designlab.code-template',
  EDITOR_VIEW = 'sgm-designlab.editor-view',
  EDITOR_THEME = 'sgm-designlab.editor-theme',
  EDITOR_SGBS_VERSION = 'sgm-designlab.editor-sgbs-version',
}

export enum QueryNames {
  ALL_PLAYGROUNDS = 'all-playgrounds',
  MY_PLAYGROUNDS = 'my-playgrounds',
  TEMPLATE_PLAYGROUNDS = 'template-playgrounds',
  OFFICIAL_TEMPLATES = 'official-templates',
  RECENT_ACTIVITY = 'recent-activity',
  TEMPLATES = 'templates',
  ALL_PROJECTS = 'all-projects',
  MY_PROJECTS = 'my-projects',
}
