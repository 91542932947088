import { IPlayground } from '../../interfaces';
import { PlaygroundMetadata } from '../../interfaces/metadata';
import { Project } from '../../interfaces/project';
import { getUserSub, trackEvent } from '../../services';
import { Breakpoint, PlaygroundRight } from '../../types';

//

export const fullscreen = () => {
  trackEvent('Fullscreen', 'open');
  document.body.classList.add('fullscreen', 'ps-12px');
};

export const defaultscreen = () => {
  trackEvent('Fullscreen', 'close');
  document.body.classList.remove('fullscreen', 'ps-12px');
};

export const toggleFullscreen = () => {
  document.body.classList.contains('fullscreen') ? defaultscreen() : fullscreen();
};

export function playgroundRights(playground: IPlayground): PlaygroundRight {
  if (!playground.shortId) {
    return PlaygroundRight.Owner;
  }
  const user = getUserSub();
  if (playground.owner === user) {
    return PlaygroundRight.Owner;
  }
  const member = playground.team.find((m) => m.mail === user);
  return member ? member.right : PlaygroundRight.None;
}

export function selectInputContent(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
  event.target.select();
}

export const EMPTY_METADATA: PlaygroundMetadata = {
  editors: {
    'main-editor': {
      markers: {
        ranges: [],
      },
    },
    'component-editor': {
      markers: {
        ranges: [],
      },
    },
  },
};

export const EMPTY_PLAYGROUND: IPlayground = {
  owner: '',
  name: '',
  code: '',
  description: '',
  team: [],
  tags: [''],
  shortId: '',
  creationDate: '',
  lastUpdate: '',
  privacy: 'public',
  exposed: false,
  dark: false,
  template: false,
  sgbsVersion: '',
  metadata: EMPTY_METADATA,
};

export const EMPTY_PROJECT: Project = {
  ownerId: '',
  ownerMail: '',
  name: '',
  description: '',
  team: [],
  playgrounds: [],
  id: '',
  creationDate: '',
  lastUpdate: '',
  privacy: 'public',
};

export const BreakpointWidth: Record<Breakpoint, string> = {
  auto: '100%',
  xs: '320px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1300px',
  xxl: '1600px',
};

export const COLORS = ['socgen', 'primary', 'info', 'success'];

const TITLE_MAX_CHAR = 35;

export const displayPGName = (str: string) =>
  str.length > TITLE_MAX_CHAR ? `${str.substr(0, TITLE_MAX_CHAR)}...` : str;

export const displayOwner = (email: string) => {
  return email.split('@')[0].split('.').join(' ');
};

export const displayDate = (date: number) => {
  const obj = new Date(date);
  return obj.toLocaleString('en-GB', { timeZone: 'UTC' });
};

export const getAvatarColor = (user: string) => {
  // It's not me
  const name = user.split('@')[0];
  let completeName: string[] = [];
  if (name.indexOf('.') > -1) {
    completeName = name.split('.');
  } else {
    completeName[0] = name;
  }
  const random =
    completeName[0].length * (completeName[1] ? completeName[1].length : Math.floor(Math.random() * 10) + 1);
  const ref = parseInt(Math.cos(random).toString()[3], 10);

  const colors = [
    'copper-rust',
    'mako',
    'puerto-rico',
    'plantation',
    'valencia',
    'azure',
    'apricot',
    'buttercup',
    'royal-blue',
    'cornflower-blue',
  ];

  return colors[ref];
};
