import { getRequestHeaders } from '@sgwt/react-shared-libs';
import { IPlayground } from '../interfaces';
import { Project } from '../interfaces/project';
import { PRIVACY_KEYS, PROJECT_PRIVACY_KEYS } from '../types';
import { LogEvent } from './logger';
import { encodeData } from './utils.service';

const BASE_API = window.globalConfiguration.apiUrl;
const PLAYGROUND_API_URL = `${BASE_API}/sgbs-playground/`;
const PROJECT_API_URL = `${BASE_API}/projects/`;

const PLAYGROUNDS_PER_PAGE = 16;
const RECENT_ACTIVITY_LIMIT = 4;
const CONTENT_TYPE = 'application/json';

// -------------------------------------------
// PLAYGROUND
// -------------------------------------------
const getPlaygrounds = async (
  page: number,
  search: string,
  privacy: PRIVACY_KEYS,
  template: boolean,
  limit: number = PLAYGROUNDS_PER_PAGE,
  orphan = false,
  signal: AbortSignal | null | undefined = undefined
) => {
  const start = (page - 1) * limit;
  let url = `${PLAYGROUND_API_URL}?privacy=${privacy}&template=${template}&start=${start}&limit=${limit}&orphan=${orphan}`;
  if (search) {
    url += `&q=${search}`;
  }
  return fetch(url, { headers: getRequestHeaders(), signal }).then((response) => response.json());
};

const getRecentActivity = async () => {
  const url = `${PLAYGROUND_API_URL}recent?limit=${RECENT_ACTIVITY_LIMIT}`;
  return fetch(url, { headers: getRequestHeaders() }).then((response) => response.json());
};

const get = async (playgroundId: string) =>
  fetch(PLAYGROUND_API_URL + playgroundId, { headers: getRequestHeaders() }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const getCapture = async (playgroundId: string) =>
  fetch(`${BASE_API}/capture/${playgroundId}`, { headers: getRequestHeaders() }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const save = async (playground: IPlayground) =>
  fetch(PLAYGROUND_API_URL, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'POST',
    // Keep only parameters accepted by the Swagger, otherwise the request is rejected by the WAF (code 422).
    body: JSON.stringify({
      name: playground.name,
      code: encodeData(playground.code),
      description: playground.description,
      team: playground.team,
      tags: playground.tags,
      privacy: playground.privacy,
      dark: playground.dark || false,
      sgbsVersion: playground.sgbsVersion,
      metadata: JSON.stringify(playground.metadata),
    }),
  }).then((response) => response.json());

const update = async (playground: IPlayground) =>
  fetch(`${PLAYGROUND_API_URL}${playground.shortId}`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'PUT',
    body: JSON.stringify({
      ...playground,
      code: encodeData(playground.code),
      metadata: JSON.stringify(playground.metadata),
    }),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const remove = async (playgroundID: string) =>
  fetch(PLAYGROUND_API_URL + playgroundID, {
    headers: getRequestHeaders(),
    method: 'DELETE',
  });

// #endPlayground

// -------------------------------------------
// PROJECT
// -------------------------------------------
const getProjects = async (
  page: number,
  search: string,
  privacy: PROJECT_PRIVACY_KEYS,
  limit: number = PLAYGROUNDS_PER_PAGE
) => {
  const start = (page - 1) * limit;
  let url = `${PROJECT_API_URL}?privacy=${privacy}&start=${start}&limit=${limit}`;
  if (search) {
    url += `&q=${search}`;
  }
  return fetch(url, { headers: getRequestHeaders() }).then((response) => response.json());
};

const getProject = async (projectId: string) =>
  fetch(PROJECT_API_URL + projectId, { headers: getRequestHeaders() }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const createProject = async (project: Project) =>
  fetch(PROJECT_API_URL, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'POST',
    body: JSON.stringify({
      name: project.name,
      description: project.description,
      privacy: project.privacy,
      team: project.team,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const updateProject = async (project: Project) =>
  fetch(`${PROJECT_API_URL}${project.id}`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'PUT',
    body: JSON.stringify({
      ...project,
      createdBy: project.ownerMail,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const removeProject = async (projectId: string) =>
  fetch(PROJECT_API_URL + projectId, {
    headers: getRequestHeaders(),
    method: 'DELETE',
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
  });

const addPlayground = async (projectId: string, playgroundIds: string[]) =>
  fetch(`${PROJECT_API_URL}${projectId}/playgrounds?playgroundIds=${playgroundIds}`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'PUT',
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

const deleteplayground = async (projectId: string, playgroundIds: string[]) =>
  fetch(`${PROJECT_API_URL}${projectId}/playgrounds?playgroundIds=${playgroundIds}`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'DELETE',
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
  });
// #endProject

// -------------------------------------------
// LOGS
// -------------------------------------------
const logs = async (events: LogEvent[]) =>
  fetch(`${BASE_API}/logs`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
    method: 'POST',
    body: JSON.stringify({ events }),
  });

//#endLogs

// -------------------------------------------
// AVAILABLE SGBS VERSIONS
// -------------------------------------------
const getAvailableVersions = async () =>
  fetch(`${BASE_API}/sgbs/versions`, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': CONTENT_TYPE,
    },
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });

// #endAvailableSgbsVersions

const ApiServices = {
  playgrounds: {
    getOne: get,
    getAll: getPlaygrounds,
    getCapture,
    getRecentActivity,
    delete: remove,
    save,
    update,
  },
  projects: {
    getOne: getProject,
    getAll: getProjects,
    create: createProject,
    delete: removeProject,
    update: updateProject,
    addPlayground: addPlayground,
    deleteplayground: deleteplayground,
  },
  logs,
  getAvailableVersions,
};

export default ApiServices;
